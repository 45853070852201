<template>
    <section class="encuesta-crear mt-4 ml-4" v-loading="loading">
        <!-- breadcrumb -->
        <div class="row">
            <div class="col-12">
                <el-breadcrumb separator-class="el-icon-arrow-right f-15">
                    <el-breadcrumb-item :to="{ name: 'encuestas.main' }">Encuestas</el-breadcrumb-item>
                    <el-breadcrumb-item>Nueva encuesta</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="row">
            <p class="f-20 f-500 mb-4 mt-5 col-12">Nueva encuesta</p>
            <ValidationObserver tag="div" class="col-12 col-sm-9 col-md-6 col-lg-6 col-xl-4" ref="form">
                <div class="row mb-4">
                    <div class="col-12">
                        <ValidationProvider tag="div" v-slot="{errors}" rules="required|max:120" name="título">
                            <p class="f-12 pl-2 label-inputs">Título de la encuesta</p>
                            <el-input placeholder="Nombre" v-model="encuesta.nombre" show-word-limit maxlength="120" size="small"></el-input>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <ValidationProvider tag="div" v-slot="{errors}" rules="required|max:750" name="descipción">
                            <p class="f-12 pl-2 label-inputs">Descripción</p>
                            <el-input placeholder="Descripción de la encuesta" v-model="encuesta.descripcion" show-word-limit maxlength="750" type="textarea"  :rows="4" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-12">
                        <ValidationProvider tag="div" class="d-middle">
                            <el-checkbox v-model="encuesta.solo_grupo" :true-label="1" :false-label="0" />
                            <span class="ml-2">
                                Todos los residentes pueden contestar
                            </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mb-4" style="min-height: 50px;">
                    <div v-if="encuesta.solo_grupo == 0" class="col-12">
                        <ValidationProvider tag="div" v-slot="{errors}" :rules="`${encuesta.solo_grupo == 0 && 'required'}|numeric|max_value:255`" name="cantidad">
                            <p class="f-12 pl-2 label-inputs">Máximos de encuestados por vivienda</p>
                            <el-input placeholder="Escribe el valor del elemento" v-model="encuesta.cantidad_permitida" size="small"></el-input>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <!-- btn guardar -->
                <div class="row mt-2 mt-sm-4">
                    <div class="col-12">
                        <button class="btn btn-general f-14 f-300 px-5" @click="guardar">Crear</button>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </section>
</template>
<script>
import Encuestas from '~/services/encuestas'
export default {
    data(){
        return{   
          loading:false,         
          encuesta:{
            nombre : '',
            descripcion : '',
            cantidad_permitida : '',
            solo_grupo : 0,
          }
        }
    },
    methods:{
        guardarNuevaEncuesta(){
            this.$router.push({ name: 'encuestas.crear.continuar' })
        },
         async guardar(){
          try {

            let validate = await this.$refs.form.validate()
            if(!validate) return this.notificacion('','Campos incompletos','warning') 

            this.loading = true
            const {data} = await Encuestas.guardar(this.encuesta)
              this.loading = false
              if(data.success){
                this.$router.push({ name: 'encuestas.crear.continuar', params:{id_encuesta : data.data.id} })
              }
          } catch (e){
            this.loading = false
            this.errorCatch(e)
          }
        },
    }
}
</script>
<style lang="scss" scoped>

</style>